import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import ModularPage from "../templates/ModularPage";
import { privacyPage } from "../types";

export const PrivacyStatementForApplicantsPage = ({
  data: {
    data: { privacystatementApplicant },
  },
}) => {
  const { title, description, content, seoMetaTags } =
    privacystatementApplicant;

  return (
    <ModularPage
      title={title}
      description={description}
      content={content}
      seoMetaTags={seoMetaTags}
    />
  );
};

PrivacyStatementForApplicantsPage.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      privacystatementApplicant: PropTypes.shape(privacyPage).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query getPrivacyStatementForApplicants {
    data: dato {
      privacystatementApplicant {
        seoMetaTags: _seoMetaTags {
          tag
          content
          attributes
        }
        title
        description
        content {
          ... on DatoCMS_BasicTitleDescriptionRecord {
            __typename
            id
            title
            description
            largeDescription
          }
          ... on DatoCMS_ImageRecord {
            __typename
            id
            source {
              url
            }
          }
          ... on DatoCMS_TextColumnRecord {
            __typename
            id
            title
            descriptionRight
            descriptionLeft
          }
        }
      }
    }
  }
`;

export default PrivacyStatementForApplicantsPage;
